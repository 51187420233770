import { createGlobalStyle } from "styled-components"

const Global = createGlobalStyle`
  p, a, li, span, small, div {
    font-family: ${props => props.theme.font.primary}, sans-serif;
  }
  
  a:hover {
    text-decoration: none !important;
    color: ${props => props.theme.color.gray} !important;
  }

  h1, h2, h3, h5, h6, footer{
    font-family: ${props => props.theme.font.primary};
  }

  h1 {
      font-size: 6rem;
      line-height: 7.1rem;
      font-weight: 300;
  }

  h2 {
    font-size: 4rem;
    line-height: 5.1rem;
    font-weight: 300;
  }

  h3 {
    font-size: 2rem;
    line-height: 3.1rem;
    font-weight: 300;
  }

  h4 {
    font-weight: 500;
  }

  p {
    font-size: 1.5rem;
  }

  footer {
    font-size: 1.3rem;
  }



html { font-family: ${props =>
  props.theme.font
    .primary}, sans-serif; font-feature-settings: 'dlig', 'zero', 'ss01', 'cv05', 'cv10'; font-weight: 300;}

@supports (font-variation-settings: normal) {
  html { font-family: ${props => props.theme.font.primary}, sans-serif; }
}

`

export default Global
