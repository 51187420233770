import React, { useState, Fragment } from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useScrollXPosition } from "react-use-scroll-position"
import styled from "styled-components"

import Img from "gatsby-image"

const NavbarContainer = styled.div`
  a {
    color: ${props => props.theme.color.black};
    :hover {
      text-decoration: none;
    }
  }

  .link {
    padding-bottom: 0.5rem;
  }

  .btn {
    cursor: pointer;
    :hover {
      color: ${props => props.theme.color.gray};
    }
  }

  .title {
    h3 {
      font-family: ${props => props.theme.font.primary};
      text-align: center;
    }
    padding-bottom: 1rem;
  }

  .nav-d {
    display: none;
    position: fixed;
    height: 100%;
    padding: 4rem 4rem 0rem 4rem;
    min-width: 300px;
    z-index: 1;
    background: white;
  }

  .nav-m {
    margin: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      font-size: 2.5rem;
    }
    &.open {
      button {
        div {
          outline: none;
          background: ${props => props.theme.color.black};
          &.second {
            opacity: 0;
          }
          &.first {
            transform: rotate(45deg);
            top: 1.1rem;
          }
          &.third {
            transform: rotate(-45deg);
            bottom: 1.1rem;
          }
        }
      }
    }
    button {
      height: 2.5rem;
      width: 3.5rem;
      position: relative;
      background: transparent;
      padding: 0;
      border: none;
      outline: none;
      div {
        transition: all 0.2s;
        height: 2px;
        width: 100%;
        background: ${props => props.theme.color.black};
        position: absolute;

        &.first {
          top: 0;
          left: 0;
        }
        &.second {
          top: calc(50% - 1.5px);
          left: 0;
        }
        &.third {
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  .nav-mm {
    margin-top: 2rem;
    font-size: 1.5rem;
    line-height: 2.8rem;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
    visibility: ${props => (props.open ? "visible" : "hidden")};
    height: ${props => (props.open ? "100%" : "0")};
  }

  @media (min-width: ${props => props.theme.sizes.mobile}) {
    .nav-d {
      display: ${props => (props.scrolled ? "none" : "inherit")};
    }
    .nav-m {
      display: none;
    }
    .nav-mm {
      display: none;
    }
  }
`

const LogoImg = styled(Img)`
  width: ${props => props.size};
  object-fil: cover;
  margin: auto;
`

const NavBar = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulMain(sort: { order: ASC, fields: order }) {
        nodes {
          slug
          title
          sub {
            slug
            title
          }
        }
      }
      contentfulWebsite {
        title
        logo {
          fluid {
            base64
            aspectRatio
            src
            srcSet
          }
        }
      }
    }
  `)

  const main = data.allContentfulMain.nodes
  const {
    title,
    logo: { fluid },
  } = data.contentfulWebsite

  const [open, setOpen] = useState(false)

  const scrollY = typeof window !== "undefined" ? useScrollXPosition() : 0
  const scrolled = scrollY !== 0

  const links = main.slice(0, 6).map((node, i) => {
    if (node.sub === null) {
      return (
        <div className="link main-link" key={`${node.title + i}`}>
          <Link to={`/${node.slug}`}>{node.title}</Link>
        </div>
      )
    }
    if (node.sub !== null) {
      const [openLink, setOpenLink] = useState(false)
      return (
        <Fragment>
          <div
            className="link btn main-link"
            onClick={() => setOpenLink(!openLink)}
          >
            {node.title}
          </div>
          {node.sub.map((sub, index) => {
            return (
              <Fragment>
                {openLink && (
                  <div
                    className="link sub-link"
                    key={`${node.title + sub + index}`}
                  >
                    <Link
                      to={`/${node.slug}/${sub.slug}`}
                      style={{ marginLeft: "1em" }}
                    >
                      {sub.title}
                    </Link>
                  </div>
                )}
              </Fragment>
            )
          })}
        </Fragment>
      )
    }
  })

  return (
    <NavbarContainer scrolled={scrolled} open={open}>
      <div className="nav-d">
        <div className="title">
          <Link to="/">
            <LogoImg size="12rem" fluid={fluid} />
            <h3>{title}</h3>
          </Link>
        </div>
        {links}
      </div>
      <div className={`nav-m${open ? " open" : ""}`}>
        <Link to="/">{title}</Link>
        <button
          aria-label="Toggle Menu"
          onClick={() => setOpen(!open)}
          className="icon"
        >
          <div className="first" />
          <div className="second" />
          <div className="third" />
        </button>
      </div>
      <div className="nav-mm">{links}</div>
    </NavbarContainer>
  )
}

NavBar.propTypes = {
  data: PropTypes.shape({
    allContentfulMain: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string,
          title: PropTypes.string,
          sub: PropTypes.shape({
            slug: PropTypes.string,
            title: PropTypes.string,
          }),
        })
      ),
    }).isRequired,
    contentfulWebsite: PropTypes.shape({
      title: PropTypes.string,
      logo: PropTypes.shape({
        fluid: PropTypes.shape({
          base64: PropTypes.string,
          aspectRatio: PropTypes.string,
          src: PropTypes.string,
          srcSet: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
}

export default NavBar
