import React from "react"
import PropTypes from "prop-types"

import styled, { ThemeProvider } from "styled-components"
import GlobalStyle from "../styles/Global"
import Theme from "../styles/Theme"
import Reboot from "../styles/reboot"

import NavBar from "../components/navbar"

const LayoutStyles = styled.div`
  display: flex;
  margin-top: 4rem;

  @media (max-width: ${Theme.sizes.mobile}) {
    flex-direction: column;
    margin-top: 0;
  }
`

const Layout = ({ children }) => {
  return (
    <LayoutStyles>
      <Reboot />
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <NavBar />
        <div>{children}</div>
      </ThemeProvider>
    </LayoutStyles>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
