const Theme = {
  color: {
    white: "#fff",
    black: "#000000",
    gray: "#aaa",
  },
  sizes: {
    mobile: "600px",
    tablet: "900px",
  },
  font: {
    primary: "muli",
  },
}

export default Theme
